import {
  SET_SOCKET,
  SET_SOCKET_LOADING,
} from '../types';

const INITIAL_STATE = {};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_SOCKET:
      return { ...state, [action.payload.key]: { ...state[action.payload.key], data: action.payload.data } };
    case SET_SOCKET_LOADING:
      return { ...state, [action.payload.key]: { ...state[action.payload.key], loading: action.payload.data } };
    default:
      return state;
  }
};
