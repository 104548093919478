import {
  SET_DIALOGFLOW_BANNER_LOADING,
  SET_DIALOGFLOW_BANNER_LIST,
  SET_DIALOGFLOW_BANNER,
  SET_DIALOGFLOW_BANNER_EXTRACT_LOG_TABLE,
  SET_DIALOGFLOW_BANNER_EXTRACT_LOG_TABLE_LOADING,
} from '../../types';

const INITIAL_STATE = {
  banner: null,
  dialogflowBannerList: {
    data: [],
    pagination: {},
  },
  loading: false,
  dialogflowBannerExtactLogTable: [],
  dialogflowBannerExtactLogTableLoading: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_DIALOGFLOW_BANNER_LIST:
      return {
        ...state,
        dialogflowBannerList: {
          ...state.dialogflowBannerList,
          data: action.payload.data,
          pagination: action.payload.pagination,
        },
      };
    case SET_DIALOGFLOW_BANNER:
      return { ...state, banner: action.payload };
    case SET_DIALOGFLOW_BANNER_LOADING:
      return { ...state, loading: action.payload };
    case SET_DIALOGFLOW_BANNER_EXTRACT_LOG_TABLE:
      return { ...state, dialogflowBannerExtactLogTable: action.payload };
    case SET_DIALOGFLOW_BANNER_EXTRACT_LOG_TABLE_LOADING:
      return { ...state, dialogflowBannerExtactLogTableLoading: action.payload };
    default:
      return state;
  }
};
