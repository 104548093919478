import {
  SET_FILE_LIST_LOADING,
  SET_FILE_LIST,
} from '../types';

const INITIAL_STATE = {
  fileList: {
    data: [],
    pagination: null,
  },
  loading: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_FILE_LIST:
      return { ...state, fileList: action.payload };
    case SET_FILE_LIST_LOADING:
      return { ...state, loading: action.payload };
    default:
      return state;
  }
};
