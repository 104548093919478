import layout from './layout';
import navbar from './navbar';

import gcpProject from './reducers/gcp/projectReducer';
import gcpDialogflow from './reducers/gcp/dialogflowReducer';

import dialogflowBanner from './reducers/dialogflow/bannerReducer';
import dialogflowLog from './reducers/dialogflow/logReducer';
import dialogflowSpendTime from './reducers/dialogflow/spendTimeReducer';
import file from './reducers/fileReducer';
import user from './reducers/userReducer';
import socket from './reducers/socketReducer';

const rootReducer = {
  navbar,
  layout,
  gcpProject,
  gcpDialogflow,
  dialogflowBanner,
  dialogflowLog,
  dialogflowSpendTime,
  file,
  user,
  socket,
};

export default rootReducer;
