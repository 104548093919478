import {
  SET_PROJECT_LIST_LOADING,
  SET_PROJECT_LIST,
} from '../../types';

const INITIAL_STATE = {
  projectList: {
    data: [],
  },
  loading: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_PROJECT_LIST:
      return {
        ...state,
        projectList: {
          ...state.projectList,
          data: action.payload.data,
        },
      };
    case SET_PROJECT_LIST_LOADING:
      return { ...state, loading: action.payload };
    default:
      return state;
  }
};
