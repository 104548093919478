import {
  SET_DIALOGFLOW_LOADING,
  SET_DIALOGFLOW,
} from '../../types';

const INITIAL_STATE = {
  dialogflow: null,
  loading: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_DIALOGFLOW:
      return { ...state, dialogflow: action.payload };
    case SET_DIALOGFLOW_LOADING:
      return { ...state, loading: action.payload };
    default:
      return state;
  }
};
