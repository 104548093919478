export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';

export const SET_USER_PROFILE = 'SET_USER_PROFILE';
export const SET_USER = 'SET_USER';
export const SET_USER_LOADING = 'SET_USER_LOADING';
export const SET_USER_LIST = 'SET_USER_LIST';
export const SET_USER_LIST_LOADING = 'SET_USER_LIST_LOADING';

export const SET_PROJECT_LIST_LOADING = 'SET_PROJECT_LIST_LOADING';
export const SET_PROJECT_LIST = 'SET_PROJECT_LIST';

export const SET_DIALOGFLOW_LOADING = 'SET_DIALOGFLOW_LOADING';
export const SET_DIALOGFLOW = 'SET_DIALOGFLOW';

export const SET_DIALOGFLOW_BANNER_LOADING = 'SET_DIALOGFLOW_BANNER_LOADING';
export const SET_DIALOGFLOW_BANNER_LIST = 'SET_DIALOGFLOW_BANNER_LIST';
export const SET_DIALOGFLOW_BANNER = 'SET_DIALOGFLOW_BANNER';
export const SET_DIALOGFLOW_BANNER_EXTRACT_LOG_TABLE = 'SET_DIALOGFLOW_BANNER_EXTRACT_LOG_TABLE';
export const SET_DIALOGFLOW_BANNER_EXTRACT_LOG_TABLE_LOADING = 'SET_DIALOGFLOW_BANNER_EXTRACT_LOG_TABLE_LOADING';

export const SET_FILE_LIST_LOADING = 'SET_FILE_LIST_LOADING';
export const SET_FILE_LIST = 'SET_FILE_LIST';

export const SET_DIALOGFLOW_BANNER_LOG = 'SET_DIALOGFLOW_BANNER_LOG';
export const SET_DIALOGFLOW_BANNER_LOG_LOADING = 'SET_DIALOGFLOW_BANNER_LOG_LOADING';

export const SET_DIALOGFLOW_BANNER_SPEND_TIME = 'SET_DIALOGFLOW_BANNER_SPEND_TIME';
export const SET_DIALOGFLOW_BANNER_SPEND_TIME_LOADING = 'SET_DIALOGFLOW_BANNER_SPEND_TIME_LOADING';

export const SET_SOCKET = 'SET_SOCKET';
export const SET_SOCKET_LOADING = 'SET_SOCKET_LOADING';
