import {
  SET_USER_LIST_LOADING,
  SET_USER_LIST,
  SET_USER_LOADING,
  SET_USER,
} from '../types';

const INITIAL_STATE = {
  list: {
    data: [],
  },
  listLoading: false,
  user: null,
  userLoading: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_USER_LIST:
      return { ...state, list: action.payload };
    case SET_USER_LIST_LOADING:
      return { ...state, listLoading: action.payload };
    case SET_USER:
      return { ...state, user: action.payload };
    case SET_USER_LOADING:
      return { ...state, userLoading: action.payload };
    default:
      return state;
  }
};
